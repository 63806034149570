
(function ($) {
	'use-strict';
	$(document).click(function (e) {
		"contestEmailInput" != e.target.id &&
			$(".rainEffect").hasClass("viral-shower-rain") &&
			($(".rainEffect").fadeTo("fast", 0),
			setTimeout(function () {
				$(".rainEffect").removeClass("viral-shower-rain");
			}, 900));
	}),
		$(document).ready(function () {
			$("#contestEmailGroup")
				.mouseenter(function () {
					$(".rainEffect").addClass("viral-shower-rain"), $(".rainEffect").fadeTo("fast", 1);
				})
				.mouseleave(function () {
					$("#contestEmailInput").is(":focus") ||
						($(".rainEffect").fadeTo("fast", 0),
						setTimeout(function () {
							$(".rainEffect").removeClass("viral-shower-rain");
						}, 900));
				});
		})
})(jQuery);